import React from 'react'

import { TitleSelect, CarWrapper, Image, ButtonSecondary, ItemCar, SelectedWrapper } from './styles'

import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { ButtonPrimary } from '../../../../../components/Buttons/style'
import { experiences } from '../../../../../constants'
import { navigate } from 'gatsby'
import styled from 'styled-components'

const WrapperCarInfo = styled.div`
  padding: 0 1rem;

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 15px;
  }
`

const CarSelect = ({ model, envs, location, modelPage, car }) => {
  const breakpoints = useBreakpoint()
  const url = location?.href || ''
  const experience = experiences[envs.SITE]

  const goToExperience = () => {
    navigate(`${experience[model?.experiece_class]}&callback=${url}`)
  }

  return (
    <>
      <ItemCar breakpoints={breakpoints}>
        <SelectedWrapper>
          {/* <TitlePage style={{ paddingBottom: 0 }}>{modelPage.left_top_title}</TitlePage> */}
          <TitleSelect breakpoints={breakpoints}>{modelPage.selected_vehicle_text}</TitleSelect>
        </SelectedWrapper>

        <CarWrapper>
          <Image src={`https://www.ford360guide.com${model.model_image && model.model_image.url}`}></Image>
          <WrapperCarInfo>
            <h5>{model[envs.VERSIONS.slice(0, -1)].Version_Full_Name}</h5>
            <p>{model.Model_Name}</p>
          </WrapperCarInfo>
          <ButtonPrimary>
            {model.has_interactive_experience && model.experiece_class && (
              <a
                href="#"
                onClick={() => {
                  if (window.gtag && car) {
                    window.gtag('event', 'interactive-experience', {
                      event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                      event_label: window.location.href
                    })
                  }

                  goToExperience()
                }}
                style={{ cursor: 'pointer' }}
              >
                <span style={{ marginRight: '10px' }}>{modelPage.interactive_experience_button_text}</span>
                <span className="fds-icon fds-color__text--dk-white">
                  <span className="fds-font--ford-icons__chevron-right"></span>
                </span>
              </a>
            )}
          </ButtonPrimary>
          <ButtonSecondary>
            {/* <Link to="https://staging.guia360ford.com.br/">{ modelPage.select_another_vehicle_text}</Link> */}
            <Link to="/">{modelPage?.change_car_text_button}</Link>
          </ButtonSecondary>
        </CarWrapper>
      </ItemCar>
    </>
  )
}

export default CarSelect
