import styled from 'styled-components'

// import Arrow from '../../../../assets/images/arrow.png'

export const SelectedWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px !important;
  width: 291px;
  margin-left: 20px;
`

export const TitleSelect = styled.span`
  width: 327px;
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
  color: #00095f;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 43px;
`

export const CarWrapper = styled.div`
  width: 291px;
  // height: 364px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;

  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`

export const Image = styled.div`
  width: 100%;
  height: 157px;
  background-image: ${props => 'url(' + props.src + ')'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const Text = styled.div`
  width: 90%;
  margin: auto;
  margin-bottom: 24px;
  margin-top: 19.57px;
  margin-left: 22px;
  p {
    height: 24px;
    width: 237.33px;
    color: #00095b;
    font-family: 'FordAntenna';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }
  span {
    height: 21px;
    width: 196px;
    color: #00095b;
    font-family: 'FordAntenna';
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 21px;
  }
`

export const ButtonPrimary = styled.div`
  height: 40px;
  width: 263px;
  border-radius: 20px;
  background-color: #00095b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;

  a {
    height: 24px;
    width: 179px;
    color: #ffffff;
    font-family: 'FordAntennaCondRegular';
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;
    text-decoration: none;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background-size: cover;
      position: relative;
      left: 5px;
      border-color: #ffffff;
      text-decoration: none;
      color: #ffffff;
    }
    &:hover {
      background-color: #00095f;
      border-color: #1700f4;
      color: #fff;
      text-decoration: none;
    }
  }

  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  &:hover {
    background-color: #00095f;
    border-color: #1700f4;
    color: #fff;
    transform: scale(1.07);
    text-decoration: none;
  }
`

export const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  a {
    color: #00095b;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    font-family: 'FordAntennaCondRegular';

    &:hover {
      color: #1700f4;
      text-decoration: underline;
    }
  }
`

export const ItemCar = styled.div`
  position: fixed;

  @media (max-width: 1366px) {
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    margin-bottom: 20px;
  }
`
