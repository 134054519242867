import React from 'react'
import slugify from 'slugify'

import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import InternaGrid from './InternaGrid'

import { Template } from '../../../components/template'
import { ToTopButton } from '../../../components/Buttons/ToTop'
import { experiences } from '../../../constants'

const Interna = ({ pageContext, location }) => {
  const {
    metatag_title,
    metatag_description,
    metatag_language,
    Privacy_policy_url,
    has_cookie_consent,
    go_to_top_button_text,
    privacy_policy_text,
    privacy_policy_subtext,
    privacy_policy_accept_button_text
  } = pageContext.website.website_info
  const modelName = pageContext.model.Full_Name
  const experience = experiences[pageContext.envs.SITE]
  return (
    <Template
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      subtitle={modelName}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      <Header
        model={pageContext.model}
        modelPage={pageContext.modelPage}
        hasInteractiveExperience={!!pageContext.model.has_interactive_experience}
        interactiveLink={pageContext.model?.experiece_class ? experience[pageContext.model.experiece_class] : ''}
        location={location}
        basePath={pageContext.basePath}
        header={pageContext.website.header}
        car={pageContext.car}
        envs={pageContext.envs}
      />
      <div style={{ height: 80 }}></div>
      <InternaGrid
        modelPage={pageContext.modelPage}
        modelName={slugify(pageContext.model.Full_Name.toLowerCase())}
        location={location}
        model={pageContext.model}
        envs={pageContext.envs}
        clusters={pageContext.clusters}
        features={pageContext.features}
        car={pageContext.car}
      />
      <ToTopButton buttonText={go_to_top_button_text} />
      <Footer privacyPolicyUrl={Privacy_policy_url} footer={pageContext.website.footer} header={pageContext.website.header} />
    </Template>
  )
}

export default Interna
