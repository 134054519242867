import React, { useEffect, useState } from 'react'
import Slider from '../../../../../components/Slider'
import { Link, navigate } from 'gatsby'
import { TitlePage, CardCharac, Text, TitleSlider, CharImage, CardTitle, CardLink, Divider } from './styles'
import slugify from 'slugify'
import styled from 'styled-components'
import { ButtonPrimary } from '../../../../../components/Buttons/style'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { sortByPriority, sortByObjectValue } from '../../../../../helpers'

const WrapperChars = styled.div`
  width: 95%;
  margin: 0 0 0 auto;

  @media (max-width: 768px) {
    width: 95%;
  }
`

const Characteristics = ({ model, envs, features, clusters, modelPage, location, car, isMobile }) => {
  const [featuresPerCluster, setFeaturesPerCluster] = useState({})
  const breakpoints = useBreakpoint()

  useEffect(() => {
    const tempFeaturesPerCluster = {}
    const tempHighlightedFeaturesPerCluster = {}

    features.forEach(feature => {
      const clusterName = feature[envs.FEATURE_CLUSTER].Cluster_Name
      const existingArray =
        tempFeaturesPerCluster[clusterName] && tempFeaturesPerCluster[clusterName].length
          ? tempFeaturesPerCluster[clusterName]
          : []

      tempFeaturesPerCluster[clusterName] = [...existingArray, feature]
        .sort((a, b) => {
          var featureA = a.Feature_Name.toUpperCase()
          var featureB = b.Feature_Name.toUpperCase()
          if (featureA < featureB) return -1
          if (featureA > featureB) return 1

          return 0
        })
        .sort((a, b) => {
          return sortByObjectValue(a, b, 'priority')
        })
    })

    // let existingHightlightedFeatures = []

    // // if (model.Highlighted_Features) {
    // //   existingHightlightedFeatures = model.Highlighted_Features.filter(high_feat => {
    // //     return !!features.find(ex_feat => ex_feat.Feature_Name === high_feat.Feature_Name)
    // //   })
    // // }

    // existingHightlightedFeatures.forEach(feature => {
    //   const clusterId = feature[envs.FEATURE_CLUSTER]
    //   const cluster = clusters.find(c => c.id === clusterId)

    //   const existingArray =
    //     tempHighlightedFeaturesPerCluster[cluster.Cluster_Name] &&
    //     tempHighlightedFeaturesPerCluster[cluster.Cluster_Name].length
    //       ? tempHighlightedFeaturesPerCluster[cluster.Cluster_Name]
    //       : []

    //   tempHighlightedFeaturesPerCluster[cluster.Cluster_Name] = [...existingArray, feature].sort((a, b) => {
    //     var featureA = a.Feature_Name.toUpperCase()
    //     var featureB = b.Feature_Name.toUpperCase()
    //     if (featureA < featureB) return -1
    //     if (featureA > featureB) return 1

    //     return 0
    //   })
    // })

    Object.keys(tempFeaturesPerCluster).forEach(key => {
      // if (!tempHighlightedFeaturesPerCluster[key]) {
      tempHighlightedFeaturesPerCluster[key] = tempFeaturesPerCluster[key].slice(0, 5)
      //   } else if (tempHighlightedFeaturesPerCluster[key].length < 5) {
      //     const includesNames = tempHighlightedFeaturesPerCluster[key].map(feat => feat.Feature_Name)

      //     const notIncludedFeatures = tempFeaturesPerCluster[key].filter(feat => {
      //       return !includesNames.includes(feat.Feature_Name)
      //     })

      //     const missingCount = 5 - tempHighlightedFeaturesPerCluster[key].length

      //     tempHighlightedFeaturesPerCluster[key] = [
      //       ...tempHighlightedFeaturesPerCluster[key],
      //       ...notIncludedFeatures.slice(0, missingCount)
      //     ]
      //   } else if (tempHighlightedFeaturesPerCluster[key].length > 5) {
      //     tempHighlightedFeaturesPerCluster[key] = tempHighlightedFeaturesPerCluster[key].slice(0, 5)
      //   }
    })

    setFeaturesPerCluster(tempHighlightedFeaturesPerCluster)
  }, [model, features, envs, clusters])

  return (
    <WrapperChars>
      {isMobile && <TitlePage>{modelPage.right_top_title}</TitlePage>}
      {Object.keys(featuresPerCluster)
        .sort((a, b) => {
          return sortByPriority(a, b, clusters)
        })
        .map(clusterName => {
          return (
            <div style={{ marginTop: '31px' }}>
              <Divider />
              <TitleSlider>{clusterName}</TitleSlider>

              <ShowSlider
                clusterName={clusterName}
                featuresPerCluster={featuresPerCluster}
                breakpoints={breakpoints}
                location={location}
                modelPage={modelPage}
                car={car}
                envs={envs}
              />

              <ButtonPrimary>
                <Link
                  to={`characteristics?char=${clusterName}`}
                  onClick={() => {
                    if (window.gtag && car) {
                      window.gtag('event', 'feature-see-all-equipment', {
                        event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                        event_label: window.location.href
                      })
                    }
                  }}
                >
                  <span style={{ marginRight: '10px' }}>{modelPage.see_all_features_button_text}</span>
                  <span className="fds-icon fds-color__text--dk-white" style={{ fontSize: '9px !important' }}>
                    <span className="fds-font--ford-icons__chevron-right" style={{ fontSize: '9px !important' }}></span>
                  </span>
                </Link>
              </ButtonPrimary>
            </div>
          )
        })}
    </WrapperChars>
  )
}

const ShowSlider = ({ breakpoints, featuresPerCluster, clusterName, location, modelPage, car, envs }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  useEffect(() => {
    if (window.gtag && car) {
      window.gtag('event', `arrow-${activeItemIndex + 1}`, {
        event_category: car.car_name.toLowerCase(),
        event_label: window.location.href
      })
    }
  }, [activeItemIndex])

  return (
    <Slider activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex}>
      {featuresPerCluster[clusterName].map(feat => {
        const imageUrl = feat?.media_1?.Image_or_Video_Cover?.url

        return (
          <CardCharac>
            <CharImage url={imageUrl && `https://www.ford360guide.com${imageUrl}`} />

            <Text>
              <CardTitle>
                <span>{feat.Feature_Name}</span>
              </CardTitle>
              <CardLink style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    if (window.gtag && car) {
                      window.gtag('event', 'feature-see-details', {
                        event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                        event_label: window.location.href
                      })
                    }

                    navigate(`characteristics/${slugify(feat.Feature_Name.toLowerCase())}/?callback=${location.href}`)
                  }}
                >
                  <a className="text">{modelPage.see_details_feature_card_text}</a>

                  <span className="fds-icon" style={{ display: 'block', transform: 'rotate(180deg)' }}>
                    <span className="fds-font--ford-icons__chevron-filled-left-path1-with-hover"></span>
                    <span className="fds-font--ford-icons__chevron-filled-left-path2"></span>
                  </span>
                </a>
              </CardLink>
            </Text>
          </CardCharac>
        )
      })}

      <CardCharac></CardCharac>
      <CardCharac></CardCharac>
    </Slider>
  )
}

export default Characteristics
