import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f4f4f4;
  min-height: 80vh;
`

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const GridWrapper = styled.div`
  display: flex;
  /* display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 100px; */
  padding-top: 51.66px;
  padding-left: 100px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const LeftColumn = styled.div`
  width: 22%;
  position: relative;

  @media (min-width: 1440px) {
    width: 25%;
  }
`

export const RightColumn = styled.div`
  width: 75%;
`
