import React from 'react'

import CarSelect from './CarSelect'
import CarSelectMobile from './CarSelectMobile'
import Characteristics from './Characteristics'
import { GridWrapper, LeftColumn, RightColumn, Container, MobileWrapper } from './styles'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const InternaGrid = ({ model, envs, clusters, features, location, modelPage, car }) => {
  const breakpoints = useBreakpoint()

  return (
    <Container breakpoints={breakpoints}>
      {breakpoints.mt ? (
        <MobileWrapper>
          <CarSelectMobile model={model} envs={envs} location={location} modelPage={modelPage} hasWrapper={true} car={car} />
          <Characteristics
            location={location}
            modelPage={modelPage}
            features={features}
            model={model}
            clusters={clusters}
            envs={envs}
            car={car}
          />
        </MobileWrapper>
      ) : (
        <GridWrapper>
          <LeftColumn>
            <CarSelect model={model} envs={envs} location={location} modelPage={modelPage} car={car} />
          </LeftColumn>
          <RightColumn>
            <Characteristics
              location={location}
              modelPage={modelPage}
              features={features}
              model={model}
              clusters={clusters}
              envs={envs}
              car={car}
              isMobile
            />
          </RightColumn>
        </GridWrapper>
      )}
    </Container>
  )
}

export default InternaGrid
